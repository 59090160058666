<template>
  <div class="news-card col-3 pt-4">
    <div class="card card-blog h-100">
      <div class="card-header p-0 mt-n4 mx-3">
        <router-link
          class="news-card__image-container d-flex justify-content-center align-items-center
                 shadow-xl border-radius-xl bg-gradient-dark"
          :to="link"
        >
          <img
            v-if="image"
            :alt="name"
            class="news-card__image img-fluid shadow w-100 h-100 border-radius-xl"
            :src="image"
          />
          <i
            v-else
            class="d-block material-icons text-secondary fs-1"
          >
            nieuwsbericht
          </i>
        </router-link>
      </div>

      <div class="d-flex flex-column card-body p-3">
        <p class="mb-0 text-sm">
          {{  publishDateString  }} // {{ publishTimeString }}
        </p>

        <router-link :to="link">
          <h5
            :class="[
              { 'text-primary font-weight-bolder mb-0': featured }
            ]"
          >
            {{ name }}
          </h5>
        </router-link>

        <div class="d-flex align-items-center justify-content-between mt-auto">
          <router-link
            :class="[
              'btn',
              'btn-sm',
              'mb-2',
              { 'btn-outline-primary': !featured },
              { 'btn-primary': featured },
            ]"
            :to="link"
          >
            {{
              newsItemId
                ? 'Lees bericht'
                : 'Bericht toevoegen'
            }}
          </router-link>
          
          <div 
            v-if="reactionCount != null && readCount != null"
            class="news-card__metrics"
          >
            <p>{{ reactionCount }} reacties</p>
            <p>{{ readCount }} keer gelezen</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseDateTimeRange } from '@/helpers/parsers';
import { getDateString, getTimeString } from '@/helpers/datetime'

export default {
  name: 'NewsCard',

  props: {
    description: {
      type: String,
      required: true,
    },
    newsItemId: {
      type: Number,
      default: 0,
    },
    reactionCount: {
      type: Number,
      required: true
    },
    readCount: {
      type: Number,
      required: true
    },
    featured: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    publishDateTime: {
      type: String,
      default: '',
    },
  },

  computed: {
    link() {
      return this.newsItemId
        ? {
          name: 'edit-newsitem',
          params: {
            id: this.newsItemId,
          },
        }
        : {
          name: 'new-newsitem',
        };
    },
    publishDateString() { return getDateString(new Date(this.publishDateTime)); },
    publishTimeString() { return getTimeString(new Date(this.publishDateTime)); }
  },

  methods: {
    parseDateTimeRange,
  },
};
</script>

<style scoped lang="scss">
.news-card {
  &:nth-child(n + 5) {
    margin-top: var(--bs-gutter-x);
  }

  &__image {
    object-fit: cover;

    &-container {
      height: 200px;
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__metrics p {
    text-align: right;
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 13px;  
  }
}
</style>
